import * as React from 'react';
import {requestSignin, updateUserEmail} from "../../store/User";
import {useAppDispatch, useAppSelector} from "../../hooks";
import NavigateButton from "../NavigateButton";
import EmailPrompt from "../EmailPrompt";
import SignUpPromo from "../SignUpPromo";

function Signin() {
    const dispatch = useAppDispatch();
    const email = useAppSelector(state => state.user.email);
    const signinDisabled = useAppSelector(state => state.user.signinDisabled);

    function handleEmailChange(evt: React.ChangeEvent<HTMLInputElement>) {
        dispatch(updateUserEmail(evt.currentTarget.value));
    }

    function handleSignin() {
        dispatch(requestSignin(email));
    }

    return (
        <div id="container">
            <div id="formcontainer">
                <form className="login">
                    <EmailPrompt className="formlogin1" label="Enter the email address registered to your Wallet88 account."
                                 value={email} onChange={handleEmailChange} />
                    <NavigateButton className="formlogin2" value="Sign In" onClick={handleSignin} disabled={signinDisabled} />
                    <SignUpPromo />
                </form>
            </div>
        </div>
    );
}

export default Signin;