export type TransactionType = "deposit" | "withdraw";

export type TransactionStatus = "approved" | "pending" | "declined" | "error";

export type DeclineReasonCode = "InsufficientFunds" | "ExceedLimits" | "BelowLimits" | "DepletedLimit" | "NotAccepted";

export type TransactionMethod = "banktransfer" | "bitcoin" | "buyandsend" | "creditcard" | "emt" | "payid" | "wallet88";

export type Action = () => void;

export interface TransactionCompleteMessage {
    type: "w88.TransactionComplete",
    status: string | null,
    'tx_action': string | null
}

export function isTransactionCompleteMessage(object: unknown): object is TransactionCompleteMessage {
    return Object.prototype.hasOwnProperty.call(object, 'type')
        && Object.prototype.hasOwnProperty.call(object, 'status')
        && Object.prototype.hasOwnProperty.call(object, 'tx_action')
        && (object as { type: string | undefined }).type == "w88.TransactionComplete";
}