export interface BitcoinAddressParts {
    address?: string,
    amount?: number,
    label?: string,
    message?: string
}

export const Bitcoin = {

    IsValidAmount: function(amount: undefined | null | number): boolean {
        return amount !== undefined && amount !== null && !Number.isNaN(amount) && Number.isFinite(amount) && amount > 0;
    },

    Format: function(amount: number): string {
        return amount.toFixed(8);
    },

    CreateUri: function ({ address, amount, label, message }: BitcoinAddressParts): string | undefined {
        if (address === undefined)
            return undefined;

        let uri = `bitcoin:${address}`;
        const params = new URLSearchParams();
        if (this.IsValidAmount(amount))
            params.append("amount", encodeURIComponent(this.Format(amount!)));
        if (label !== undefined)
            params.append("label", encodeURIComponent(label));
        if (message !== undefined)
            params.append("message", encodeURIComponent(message));
        const query = params.toString();
        return query.length == 0 ? uri : `${uri}?${query}`;
    }
}