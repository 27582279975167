import * as React from "react";
import AccountBalance from "../AccountBalance";
import Success from "../Success";
import NavigateButton from "../NavigateButton";
import SignOutButton from "../SignOutButton";

function Receipt() {
    return (
        <div id="container">
            <div id="formcontainer">
                <form className="choose">
                    <AccountBalance className="formchoose1" />
                    <Success />
                    <NavigateButton className="formchoose3" target="/" value="Done" />
                    <SignOutButton className="formchoose4" primary={false} />
                </form>
            </div>
        </div>
    );
}

export default Receipt;