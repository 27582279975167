import * as React from "react";
import {Action} from "../../types";

interface AmountPromptOptions {
    className?: string | undefined,
    label?: string | undefined,
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined,
    children?: React.ReactNode
}

function AmountPrompt(props: AmountPromptOptions) {
    const { className, label, onChange, children } = props;

    return (
        <div className={className}>
            <p className="label">{label}</p>
            <input type="number" className="amount" onChange={onChange}/>
            {children}
        </div>
    );
}

export default AmountPrompt;