import * as React from "react";
import NavigateButton from "../NavigateButton";

interface TopUpButtonOptions {
    className?: string | undefined
}

function TopUpButton(props: TopUpButtonOptions) {
    const { className } = props;
    return (<NavigateButton className={className} value="Top up" target="https://app.wallet88.dev/dashboard" />)
}

export default TopUpButton;