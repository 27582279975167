import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import {requestAccountDetails} from "../../store/Account";

interface AccountBalanceOptions {
    className?: string | undefined
}
function AccountBalance(props: AccountBalanceOptions) {
    const { className } = props;
    const user = useAppSelector(state => state.user.email);
    const balance = useAppSelector(state => state.account.balance);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (!user)
            return;

        dispatch(requestAccountDetails(user));
    }, [ user ]);

    return (
        <div className={className}>
            <p className="label">Account balance</p>
            <p className="amount">{balance}</p>
        </div>
    );
}

export default AccountBalance;