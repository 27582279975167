import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Buffer} from "buffer";

type ReasonType = "unauthorized";

interface UserState {
    accessToken: string | undefined,
    expiresAt: number | undefined,
    email: string,
    firstname: string | undefined,
    lastname: string | undefined,
    country: string | undefined,
    currency?: string,
    reason: ReasonType | undefined,
    signingIn: boolean
    signinDisabled: boolean
}

interface TokenResponse {
    'access_token': string,
    'token_type': 'Bearer',
    'expires_in': number,
    'expires_at': number,
    'scope': string | undefined
}

interface JwtClaims {
    email: string,
    given_name: string,
    family_name: string,
    country: string,
    currency: string,
    exp: number,
    iss: string,
    aud: string
}

interface UserSigninResponse {
    accessToken: string | undefined,
    expiresAt: number | undefined,
    firstname: string,
    lastname: string,
    country: string,
    currency: string
}

export const requestSignin = createAsyncThunk<UserSigninResponse, string, { rejectValue: ReasonType }>(
    'user/signin',
    async (email, thunkApi) => {
        const formData = new FormData();
        formData.append('grant-type', 'password');
        formData.append('username', email);
        formData.append('password', email);
        formData.append('scope', 'openid');

        const response = await fetch('./auth/token', {
            method: 'POST',
            body: formData
        });
        if (response.status === 401 || response.status === 403 || response.status === 404)
            return thunkApi.rejectWithValue("unauthorized");

        const tokenResponse = (JSON.parse(await response.text())) as TokenResponse;
        const accessToken = tokenResponse.access_token;
        const parts = accessToken.split('.');
        const claims = JSON.parse(Buffer.from(parts[1], "base64").toString()) as JwtClaims;
        return {
            accessToken: accessToken,
            expiresAt: tokenResponse.expires_at,
            firstname: claims.given_name,
            lastname: claims.family_name,
            country: claims.country,
            currency: claims.currency
        }
    });

const initialState = {
    accessToken: undefined,
    expiresAt: undefined,
    email: "",
    reason: undefined,
    signingIn: false,
    signinDisabled: true
} as UserState

const slice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        updateUserEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
            state.signinDisabled = state.signingIn || state.email.length <= 0;
            return state;
        },
        signOut: (state) => {
            state.email = "";
            state.signinDisabled = true;
            state.signingIn = false;
            state.reason = undefined;
            state.accessToken = undefined;
            state.expiresAt = undefined;
            state.firstname = undefined;
            state.lastname = undefined;
            state.currency = undefined;
            return state;
        }

    },
    extraReducers: builder => {
        builder.addCase(requestSignin.pending, (state) => {
            state.signinDisabled = true;
            state.signingIn = true;
            state.firstname = undefined;
            state.lastname = undefined;
            state.country = undefined;
            state.currency = undefined;
            return state;
        });
        builder.addCase(requestSignin.fulfilled, (state, action) => {
            const payload = action.payload;

            state.signingIn = false;
            state.accessToken = payload.accessToken;
            state.expiresAt = payload.expiresAt;
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.country = action.payload.country;
            state.currency = action.payload.currency;
            return state;

        });
        builder.addCase(requestSignin.rejected, (state, action) => {
            state.signingIn = false;
            state.reason = action.payload;
            return state;
        });
    }
})

export const {
    updateUserEmail,
    signOut
} = slice.actions;

export default slice.reducer;