import * as React from "react";
import { useEffect } from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import Receipt from "../Receipt";
import ErrorPage from "../ErrorPage";
import Transaction from "../Transaction";
import {
    DeclineReasonCode,
    TransactionType,
    TransactionCompleteMessage, isTransactionCompleteMessage
} from "../../types";
import {initialize, updateStatus} from "../../store/Transaction";
import DeclinedHeader from "../DeclinedHeader";
import BackButton from "../BackButton";
import TopUpButton from "../TopUpButton";
import TransactionPrompt from "../TransactionPrompt";
import TransactionProcessing from "../TransactionProcessing";
import {useNavigate} from "react-router-dom";


interface DeclineContent {
    message: React.ReactNode | undefined,
    buttons: React.ReactNode | undefined
}

interface TransactionWithReceiptPageOptions {
    type: TransactionType
}

function TransactionWithReceiptPage(props: TransactionWithReceiptPageOptions) {
    const { type } = props;
    const { status, reason, contentType } = useAppSelector(state => {
        return {
            status: state.transaction.status,
            reason: state.transaction.reason,
            contentType: state.transaction.contentType
        };
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const origin = window.location.protocol + "//" + window.location.hostname
        function handleTxProcessTransactionCompleteMessages(evt: MessageEvent) : boolean {
            if (evt.origin != origin)
                return false;

            if (!isTransactionCompleteMessage(evt.data))
                return false;

            const message: TransactionCompleteMessage = evt.data;
            dispatch(updateStatus(message));
            return true;
        }

        function handleReturnFromBuyAndSend(evt: MessageEvent) : boolean {
            if (typeof evt.data !== "object" || !evt.data.type)
                return false;

            if (evt.data.type !== 'transaction_update' || evt.data.message !== "complete")
                return false;

            const message: TransactionCompleteMessage = {
                type: "w88.TransactionComplete",
                status: "OK",
                tx_action: "PAYMENT"
            };
            dispatch(updateStatus(message));
            return true;
        }

        function handlerWallet88StartOver(evt: MessageEvent): boolean {
            if (typeof evt.data !== "object" ||
                evt.data.source !== 'w88.cashier' ||
                evt.data.type !== 'w88.cashier.start_over')
                return false;

            dispatch(initialize(type));
            return true;
        }

        const handlers = [
            handleTxProcessTransactionCompleteMessages,
            handleReturnFromBuyAndSend,
            handlerWallet88StartOver
        ] as ((evt: MessageEvent) => boolean)[];
        function postMessageHandler(ev: MessageEvent) {
            for (const handler of handlers) {
                if (handler(ev)) {
                    return;
                }
            }
        }

        window.addEventListener("message", postMessageHandler);
        return () => {
            window.removeEventListener("message", postMessageHandler);
        }
    }, []);

    function handleClick() {
        if (type == undefined)
            return;

        dispatch(initialize(type));
    }

    function DeclineContentFor(reason: DeclineReasonCode | undefined): DeclineContent {
        if (reason == undefined)
            return {
                message: undefined,
                buttons: undefined
            };

        switch (reason) {
            case "BelowLimits":
                return {
                    message: (<>This transaction is below the minimum transfer limit. Please go back and enter an amount
                        larger than <span style={{fontFamily: 'GroteskMedium', color: "#ff0036"}}>$10.00</span>.</>),
                    buttons: (<BackButton className="formchoose3" onClick={handleClick}/>)
                };

            case "ExceedLimits":
                return {
                    message: (<>This transaction exceeds your monthly transfer limit. Please go back and enter an amount
                        less
                        than <span style={{fontFamily: 'GroteskMedium', color: "#ff0036"}}>$325.33</span>.</>),
                    buttons: (<BackButton className="formchoose3" onClick={handleClick}/>)
                };

            case "DepletedLimit":
                return {
                    message: (<>The maximum transfer limit for this method has been used. Please go back and choose
                        another
                        method.</>),
                    buttons: (<BackButton className="formchoose3" onClick={handleClick}/>)
                };

            case "InsufficientFunds":
                return {
                    message: (<>Insufficient funds for this transaction. Please go back and enter an amount less than <span
                        style={{fontFamily: 'GroteskMedium'}}>$325.33</span> or <a
                        href="https://app.wallet88.dev/dashboard">top up your Wallet88 account</a>.</>),
                    buttons: (
                        <>
                            <TopUpButton className="formchoose3" />
                            <BackButton className="formchoose4" primary={false} onClick={handleClick}/>
                        </>
                    )
                };

            case "NotAccepted":
                return {
                    message: (<>Your transaction was declined. Contact us for support or try another method.</>),
                    buttons: (<BackButton className="formchoose3" primary={false} onClick={handleClick}/>)
                };
        }
    }

    if (status === "approved")
        return (<Receipt/>);

    if (status === "error") {
        const button = (<BackButton className="formchoose3" target="/" />);
        return (<ErrorPage buttons={button}><p>An error occured. We were unable to transfer funds.</p></ErrorPage>);
    }

    if (status === "declined") {
        const { message, buttons } = DeclineContentFor(reason);
        return (<ErrorPage buttons={buttons}><DeclinedHeader/><p>{message}</p></ErrorPage>);
    }

    if (status === "pending") {
        if (contentType === undefined)
            return <TransactionProcessing />;

        return (<TransactionPrompt />);
    }

    return (<Transaction type={type} />)
}

export default TransactionWithReceiptPage;