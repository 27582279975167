import * as React from "react";

interface ErrorMessageOptions {
    children?: React.ReactNode
}
function ErrorMessage(props: ErrorMessageOptions) {
    const { children } = props;
    return (<p className="error">{children}</p>);
}

export default ErrorMessage;