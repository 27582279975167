import { configureStore } from '@reduxjs/toolkit';
import User from "./User";
import Account from "./Account";
import Bitcoin from "./Bitcoin";
import Transaction from "./Transaction";

export const store = configureStore({
    reducer: {
        user: User,
        account: Account,
        bitcoin: Bitcoin,
        transaction: Transaction
    }
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch