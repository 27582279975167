import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    initialize,
    requestTransaction,
    updateAmount,
    updateMethod
} from "../../store/Transaction";
import NavigateButton from "../NavigateButton";
import AmountPrompt from "../AmountPrompt";
import ErrorMessage from "../ErrorMessage";
import {TransactionMethod, TransactionType} from "../../types";
import {useEffect, ReactNode, useState} from "react";
import Regions from "../../app/Regions";
import {
    CardInput
    , Chest
    , Drawer, Icons
    , Payments
    , PaymentsIcon
    , Spinner
    , TextInputWithIcon
} from "@w88/react-components";
import style from './index.module.css';
import QRCode from "qrcode.react";
import {requestBitcoinAddress, requestBitcoinRate} from "../../store/Bitcoin";
import { Bitcoin } from "../../app/Bitcoin";

interface PaymentChoiceOptions {
    value: string,
    label: string,
    icon: PaymentsIcon,
    children?: ReactNode,
}

interface BitcoinQRCodeOptions {
    visible: boolean
}
function BitcoinQRCode({ visible }: BitcoinQRCodeOptions): JSX.Element {
    const dispatch = useAppDispatch();
    const { address, btcAmount, failed, rate, uri} = useAppSelector(state => {
        return {
            address: state.bitcoin.address,
            btcAmount: state.bitcoin.btcAmount,
            failed: state.bitcoin.failed,
            rate: state.bitcoin.rate,
            uri: state.bitcoin.uri
        };
    })

    useEffect(() => {
        if (visible && uri === undefined) {
            dispatch(requestBitcoinAddress());
        }

        let timeout = undefined as NodeJS.Timeout | undefined;
        if (visible) {
            if (rate === undefined)
                dispatch(requestBitcoinRate());

            timeout = setTimeout(() => {
                dispatch(requestBitcoinRate());
            }, 10000);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [ visible, dispatch ]);

    const imageSettings = {width: 90, height: 90, src: "", excavate: false};
    return (<div className={style.spinner_container}>
        { failed && <Icons className={`${style.bitcoin} ${style.error} ${style.icon}`} icon={"emoji-crying"} size={"medium"} theme={"dark"} /> }
        { !failed && <Spinner size={"medium"} theme={"dark"} busy={uri === undefined}>
            { uri && <QRCode className={style.qrcode} value={uri} renderAs="svg" imageSettings={imageSettings}/> }
            { address && <div className={style.payto}>or<br />Send { Bitcoin.IsValidAmount(btcAmount) ? `${ Bitcoin.Format(btcAmount!) } BTC` : "bitcoin" } to {address}</div> }
        </Spinner> }
    </div>);
}

interface TransactionOptions {
    type: TransactionType
}

function Transaction({type}: TransactionOptions) {
    const {country, amount, activeMethod, card, error, submitDisabled} = useAppSelector(state => {
        return {
            country: state.user.country,
            amount: state.transaction.amount,
            activeMethod: state.transaction.method,
            card: state.transaction.card,
            error: state.transaction.message,
            submitDisabled: state.transaction.submitDisabled
        };
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [over, setOver] = useState<string | undefined>(undefined);
    const title = "Amount to " + (type === "deposit" ? "deposit" : "withdraw");
    const actionLabel = type === "deposit" ? "Deposit" : "Withdraw";
    const additional = error ? (<ErrorMessage>{error}</ErrorMessage>) : null;

    useEffect(() => {
        dispatch(initialize(type));
    }, [type, dispatch])

    function handleAmountChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const amount = Number.parseFloat(evt.currentTarget.value);
        dispatch(updateAmount(amount));
    }

    function handleSubmit(): void {
        dispatch(requestTransaction());
    }

    function handleCancel(): void {
        navigate("/", {replace: true});
    }

    function handleMethodChange(value: string): void {
        dispatch(updateMethod(value as TransactionMethod));
    }

    function handleMouseOver(value: string): void {
        setOver(value);
    }

    function handleMouseOut(value: string): void {
        if (over  === value) {
            setOver(undefined);
        }
    }

    function createCreditCardContent() {
        return (<div className={style.card_container}>
            <CardInput id={'card'} className={style.card} value={card?.card_number ?? ''} />
            <TextInputWithIcon id={'expiry'} className={style.expiry} type={'text'} autoComplete={'cc-exp'} placeholder={'MMYY'} value={card?.expiry ?? ''}/>
            <TextInputWithIcon id={'csc'} className={style.csc} type={'text'} autoComplete={'cc-csc'} placeholder={'CSV'} value={card?.cvv ?? ''}/>
        </div>);
    }
    
    function PaymentChoice({label, icon, value, children}: PaymentChoiceOptions) {
        const theme = (activeMethod === value) || (over === value) ? "light" : "dark";
        return <Drawer key={value}
                       value={value}
                       onMouseOut={() => handleMouseOut(value)}
                       onMouseOver={() => handleMouseOver(value)}
                       face={<>
                           <span className={style.label}>{label}</span>
                           <Payments icon={icon} size={"small"} theme={theme} className={style.icon} />
                       </>}>{children}</Drawer>;
    }

    const options = [];
    options.push(PaymentChoice({value: "buyandsend", label: "Buy + Send", icon: "buyandsend"}));
    options.push(PaymentChoice({value: "flypto", label: "Flypto", icon: "flypto"}));
    options.push(PaymentChoice({value: "creditcard", label: "Credit Card", icon: "creditcard", children: createCreditCardContent()}));
    options.push(PaymentChoice({value: "bitcoin", label: "Bitcoin", icon: "bitcoin",
        children: <BitcoinQRCode visible={ activeMethod === "bitcoin"} />}));
    
    if (country === "CA") {
        options.push(PaymentChoice({value: "emt", label: "Interac", icon: "interacRequest"}));
    }
    if (country === "AU") {
        options.push(PaymentChoice({value: "payid", label: "PayID", icon: "payid"}));
    }
    if (country === "IN") {
        options.push(PaymentChoice({value: "gpay", label: "UPI - GPay", icon: "gpay"}));
        options.push(PaymentChoice({value: "paytm", label: "UPI - PayTM", icon: "paytm"}));
        options.push(PaymentChoice({value: "phonepe", label: "UPI - PhonePe", icon: "phonepe"}));
        options.push(PaymentChoice({value: "UPI_India", label: "UPI", icon: "upiIndia"}));
        options.push(PaymentChoice({value: "banktransfer", label: "Net Banking", icon: "banktransfer"}));
    }
    if (country && Regions.IsWestEurope(country)) {
        options.push(PaymentChoice({value: "sofort", label: "Sofort", icon: "sofort"}));
    }
    options.push(PaymentChoice({value: "wallet88", label: "Wallet88", icon: "wallet88"}));

    return (
        <div id="container">
            <div id="formcontainer">
                <form className="choose">
                    <AmountPrompt className="formchoose1" label={title} onChange={handleAmountChange}>
                        {additional}
                    </AmountPrompt>
                    {type == "deposit" ? <Chest id={"paymentmethod"} className={style.payment_options} onChange={handleMethodChange}>{options}</Chest> : null}
                    <NavigateButton className="formchoose3" value={actionLabel} onClick={handleSubmit} disabled={submitDisabled}/>
                    <NavigateButton className="formchoose4" primary={false} value="Cancel" onClick={handleCancel}/>
                </form>
            </div>
        </div>);
}

export default Transaction;