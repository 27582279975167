import * as React from "react";
import HtmlDocument from "../HtmlDocument";
import {useAppSelector} from "../../hooks";

function TransactionPrompt() {
    const { contentType, content} = useAppSelector(state => {
        return {
            contentType: state.transaction.contentType,
            content: state.transaction.content
        };
    });
    return (
        <div id="container">
            <div id="iframecontainer">
                <HtmlDocument id="transaction-prompt"
                              src={contentType === 'url' ? content : undefined}
                              content={contentType === 'html' ? content : undefined}
                />
            </div>
        </div>);
}

export default TransactionPrompt;