import * as React from "react";

function SignUpPromo() {
    return (
        <p style={{margin: "1.1rem 0 -1.2rem"}}>
            Don't have an account?<br/>
            <a href="https://auth.wallet88.dev/realms/wallet88/protocol/openid-connect/registrations?client_id=app&response_type=code&redirect_uri=https://app.wallet88.dev/welcome" target="_blank">Sign up with Wallet88</a>.
        </p>
    );
}

export default SignUpPromo;