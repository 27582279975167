import * as React from "react";
import { ReactComponent as ErrorIcon } from "./images/icon-fail.svg";

interface ErrorPageOptions {
    children?: React.ReactNode | undefined,
    buttons?: React.ReactNode | undefined
}

function ErrorPage(props: ErrorPageOptions) {
    const { children, buttons } = props;

    return (
        <div id="container">
            <div id="formcontainer">
                <form className="choose">
                    <div className="formchoose1" />
                    <div>
                        <ErrorIcon className="receipt-icon" />
                        {children}
                    </div>
                    {buttons}
                </form>
            </div>
        </div>
    );
}

export default ErrorPage;