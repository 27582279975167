import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../hooks";
import Signin from "../Signin";
import ErrorPage from "../ErrorPage";
import {signOut} from "../../store/User";
import BackButton from "../BackButton";

interface ProtectedOptions {
    element?: React.ReactNode,
    children?: React.ReactNode
}

function IsSignedIn(accessToken: string | undefined, expiresAt: number | undefined) {
    if (accessToken == undefined || expiresAt == undefined)
        return false;

    if (accessToken.length <= 0)
        return false;

    const now = new Date()
    const secondsSinceEpoch = Math.round(now.getTime() / 1000)
    return secondsSinceEpoch < expiresAt;
}

function Protected(props: ProtectedOptions): React.ReactElement | null {
    const accessToken = useAppSelector(state => state.user.accessToken);
    const expiresAt = useAppSelector(state => state.user.expiresAt);
    const signingIn = useAppSelector(state => state.user.signingIn);
    const reason = useAppSelector(state => state.user.reason);
    const dispatch = useAppDispatch();

    const signedIn = IsSignedIn(accessToken, expiresAt);

    function handleClick() {
        dispatch(signOut());
    }

    if (!signedIn && !signingIn && reason == "unauthorized") {
        const button = (<BackButton className="formchoose3" onClick={handleClick}/>);
        return (
            <ErrorPage buttons={button}>
                <p className="error-message">No Wallet88 account for that email address. Go Back and try again, or <a
                    href="https://auth.wallet88.dev/realms/wallet88/protocol/openid-connect/registrations?client_id=app&response_type=code&redirect_uri=https://app.wallet88.dev/welcome"
                    target="_blank">sign up an account with Wallet88</a>.</p>
            </ErrorPage>
        );
    }

    if (!signedIn)
        return (<Signin/>);

    return (<React.Fragment>{props.element ? props.element : props.children}</React.Fragment>);
}

export default Protected;