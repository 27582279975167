import * as React from "react";
import IframeResizer from "iframe-resizer-react";
import {useEffect} from "react";

interface HtmlDocumentOptions {
    id: string,
    src?: string | undefined
    content?: string | undefined
}

function HtmlDocument(props: HtmlDocumentOptions) {
    const { id, src, content } = props;

    useEffect(() => {
        if (content == undefined)
            return;

        const container = document.getElementById(id) as HTMLIFrameElement;
        if (container == undefined)
            return;

        const window = container.contentWindow;
        if (window == undefined)
            return;

        const doc = window.document;
        doc.open();
        doc.write(content);
        doc.close();
    }, [])

    return <IframeResizer
        id={id}
        src={src}
        sandbox="allow-scripts allow-popups allow-same-origin allow-forms allow-top-navigation"
        style={{position: "fixed", left: "0", right: "0", height: "100vh", width: "100vw"}}
        className="iframe"
        autoResize={false}
        heightCalculationMethod="max"
        checkOrigin={false}
        scrolling="omit"
    />
}

export default  HtmlDocument;