import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {Action} from "../../types";

interface NavigateButtonOptions {
    className?: string | undefined,
    target?: string | undefined,
    onClick?: Action,
    value?: string | undefined,
    primary?: boolean | undefined,
    disabled?: boolean | undefined
}


function NavigateButton(props: NavigateButtonOptions) {
    const { className, primary, value, target, onClick, disabled } = props;
    const navigate = useNavigate();

    function handleNavigate(): void {
        if (onClick)
            onClick();

        if (target) {
            if (target.startsWith("http://") || target.startsWith("https://")) {
                window.location.replace(target);
            } else {
                navigate(target, {replace: false});
            }
        }
    }

    let aClassName = "primarybutton";
    if (primary != undefined && !primary) {
        aClassName += " buttonsecondary";
    }

    return (
        <div className={className}><input type="button" className={aClassName} value={value} onClick={handleNavigate} disabled={disabled} /></div>
    );
}

export default NavigateButton;