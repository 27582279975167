import * as React from 'react';
import {signOut} from "../../store/User";
import {useAppDispatch} from "../../hooks";
import NavigateButton from "../NavigateButton";

interface SignOutButtonOptions {
    className?: string | undefined,
    primary?: boolean | undefined
}

function SignOutButton(props: SignOutButtonOptions) {
    const { className, primary } = props;
    const dispatch = useAppDispatch();

    function handleSignOut() {
        dispatch(signOut());
    }

    return (<NavigateButton className={className} primary={primary} value="Sign Out" onClick={handleSignOut} target="/" />);
}

export default SignOutButton;