const WestEurope = {
    'AT': true,
    'BE': true,
    'BG': true,
    'CH': true,
    'CY': true,
    'CZ': true,
    'DE': true,
    'DK': true,
    'EE': true,
    'ES': true,
    'FI': true,
    'FR': true,
    'GR': true,
    'HR': true,
    'HU': true,
    'IE': true,
    'IT': true,
    'LI': true,
    'LT': true,
    'LU': true,
    'LV': true,
    'MT': true,
    'NL': true,
    'PL': true,
    'PT': true,
    'RO': true,
    'SI': true,
    'SK': true
} as { [key: string]: boolean }

const Regions = {
    IsWestEurope(country: string): boolean {
        return WestEurope[country];
    }
};

export default Regions;