import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {RootState} from "./index";
import {updateAmount} from "./Transaction";
import { Bitcoin } from "../app/Bitcoin";

interface BitcoinAddressResponse {
    address?: string
}

export const requestBitcoinAddress = createAsyncThunk<BitcoinAddressResponse, void, { state: RootState, rejectValue: string}>(
    "bitcoinAddress",
    async (_, thunkApi) => {
        const token = thunkApi.getState().user.accessToken;
        const response = await fetch(`${window.location.origin}/account/bitcoin`, {
            method: "GET",
            headers: new Headers({
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            })
        });
        if (response.status === 401 || response.status === 403)
            return thunkApi.rejectWithValue("Unauthorized");

        if (response.status != 200 && response.status != 201 && response.status != 202 && response.status != 301)
            return thunkApi.rejectWithValue("Service unavailable, type again later");

        return (await response.json()) as BitcoinAddressResponse
    }
);

interface BitcoinRateResponse {
    from: string,
    to: string,
    rate: number
}

export const requestBitcoinRate = createAsyncThunk<BitcoinRateResponse, void, { state: RootState, rejectValue: string}>(
    "bitcoinRate",
    async (_, thunkApi) => {
        const user = thunkApi.getState().user;
        const token = user.accessToken;
        const from = user.currency;
        const to = "BTC";
        const response = await fetch(`${window.location.origin}/FxRates?f=${from}&t=${to}`, {
            method: "GET",
            headers: new Headers({
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            })
        });
        if (response.status === 401 || response.status === 403)
            return thunkApi.rejectWithValue("Unauthorized");

        if (response.status != 200 && response.status != 201 && response.status != 202 && response.status != 301)
            return thunkApi.rejectWithValue("Service unavailable, type again later");

        return (await response.json()) as BitcoinRateResponse
    }
);
interface BitcoinState {
    failed: boolean,
    amount?: number,
    rate?: number,
    btcAmount?: number,
    address?: string,
    uri?: string
}

const initialState = {
    failed: false
} as BitcoinState;

const slice = createSlice({
    name: 'bitcoin',
    initialState: initialState,
    reducers: {
    },
    extraReducers: builder => {
        builder.addCase(requestBitcoinAddress.rejected, (state) => {
            state.failed = true;
            return state;
        });
        builder.addCase(requestBitcoinAddress.pending, (state) => {
            state.failed = false;
            state.address = undefined;
            state.uri = undefined;
            return state;
        });
        builder.addCase(requestBitcoinAddress.fulfilled, (state, action) => {
            const address =  action.payload.address;
            state.failed = false;
            state.address = address;
            state.uri = Bitcoin.CreateUri({
                address: address,
                label: "Demo",
                message: "Payment for invoice 43256",
                amount: state.btcAmount,
            });
            return state;
        });
        builder.addCase(requestBitcoinRate.fulfilled, (state, action) => {
            const rate = action.payload.rate;
            const btcAmount = Bitcoin.IsValidAmount(state.amount) && Bitcoin.IsValidAmount(rate) ? state.amount! * rate : undefined;
            state.rate = rate;
            state.btcAmount = btcAmount;
            state.uri = Bitcoin.CreateUri({
                address: state.address,
                label: "Demo",
                message: "Payment for invoice 43256",
                amount: btcAmount,
            });
            return state;
        });
        builder.addCase(updateAmount, (state, action) => {
            const amount = action.payload;
            const btcAmount = Bitcoin.IsValidAmount(amount) && Bitcoin.IsValidAmount(state.rate) ? amount * state.rate! : undefined;
            state.amount = amount;
            state.btcAmount = btcAmount;
            state.uri = Bitcoin.CreateUri({
                address: state.address,
                label: "Demo",
                message: "Payment for invoice 43256",
                amount: btcAmount
            });
            return state;
        });
    }
})

export const {

} = slice.actions;

export default slice.reducer;