import * as React from 'react';
import {Route, Routes} from "react-router";
import Layout from './components/Layout';
import Protected from "./components/Protected";
import Home from "./components/Home";

import './custom.css'
import TransactionWithReceiptPage from "./components/TransactionWithReceiptPage";
import TransactionComplete from "./components/TransactionComplete";

export default () => (
    <Layout>
        <Routes>
            <Route path="/" element={Protected({ element: <Home key={"home"} /> })} />
            <Route path="/deposit" element={Protected({ element: <TransactionWithReceiptPage key={"deposit"} type={"deposit"} /> }) } />
            <Route path="/withdraw" element={Protected({ element: <TransactionWithReceiptPage key={"withdrawal"} type={"withdraw"} /> }) } />
            <Route path="/callback" element={<TransactionComplete key={"completed"} />} />
        </Routes>
    </Layout>
);