import * as React from "react";

interface EmailPromptOptions {
    className?: string | undefined,
    label?: string | undefined,
    value?: string | undefined,
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
}

function EmailPrompt(props: EmailPromptOptions) {
    const { className, label, value, onChange } = props;
    return (
        <div className={className}>
            <p className="label">{label}</p>
            <input type="text" id="signin_email" placeholder="Email address" value={value} onChange={onChange} />
        </div>
    );
}

export default EmailPrompt;