import * as React from "react";
import NavigateButton from "../NavigateButton";
import {Action} from "../../types";

interface BackButtonOptions {
    className?: string | undefined,
    onClick?: Action | undefined,
    target?: string | undefined,
    primary?: boolean | undefined
}
function BackButton(props: BackButtonOptions) {
    const { className, primary, onClick, target } = props;
    return (<NavigateButton className={className} primary={primary} value="Back" onClick={onClick} target={target} />);
}

export default BackButton;