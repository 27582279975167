import * as React from 'react';
import AccountBalance from "../AccountBalance";
import SignOutButton from "../SignOutButton";
import NavigateButton from "../NavigateButton";
import {TransactionType} from "../../types";
import {initialize} from "../../store/Transaction";
import {useAppDispatch} from "../../hooks";

function Home() {
    const dispatch = useAppDispatch();

    function handleClick(type: TransactionType) {
        dispatch(initialize(type));
    }

    return (
        <div id="container">
            <div id="formcontainer">
                <form className="choose">
                    <AccountBalance className="formchoose1" />
                    <NavigateButton className="formchoose2" value="Deposit" onClick={() => handleClick("deposit")} target="/deposit" />
                    <NavigateButton className="formchoose3" value="Withdraw" onClick={() => handleClick("withdraw")} target="/withdraw" />
                    <SignOutButton className="formchoose4" primary={false} />
                </form>
            </div>
        </div>
    );
}

export default Home;