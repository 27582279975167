import * as React from "react";
import { useLocation } from "react-router-dom";
import {useEffect} from "react";
import {TransactionCompleteMessage} from "../../types";

function TransactionComplete() {
    const query = new URLSearchParams(useLocation().search)

    useEffect(() => {
        const data: TransactionCompleteMessage = {
            type: "w88.TransactionComplete",
            status:  query.get("status"),
            'tx_action': query.get("tx_action")
        }
        const top = window.top;
        if (top == undefined)
            return;

        top.postMessage(data);
    }, []);
    return <div />
}

export default  TransactionComplete;