import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "./index";
import {
    DeclineReasonCode,
    TransactionCompleteMessage,
    TransactionMethod,
    TransactionStatus,
    TransactionType
} from "../types";
import {requestAccountDetails} from "./Account";

interface CreditCard {
    name_on_card: string | undefined;
    card_number: string | undefined;
    expiry: string | undefined;
    cvv: string | undefined;
}

interface TransactionState {
    type: TransactionType | undefined,
    method: TransactionMethod | undefined,
    amount: number | undefined,
    card: CreditCard | undefined,
    loading: boolean,
    submitDisabled: boolean,
    status: TransactionStatus | undefined,
    reason: DeclineReasonCode | undefined,
    contentType: string | undefined,
    content: string | undefined,
    message: string | undefined,
    balance: number | undefined
}

interface TransactionResponse {
    status: TransactionStatus,
    reason?: DeclineReasonCode | undefined
    'content-type'?: string | undefined
    content?: string | undefined
}

export const requestTransaction = createAsyncThunk<TransactionResponse, void, { state: RootState, rejectValue: string }>(
    'transaction',
    async (_, thunkApi) => {
        const token = thunkApi.getState().user.accessToken;
        const base_url = window.location.origin;
        const url = new URL(base_url + "/transaction");

        const tx = thunkApi.getState().transaction;
        let request;
        if(tx.method == "buyandsend"){
            request = {
                user: thunkApi.getState().user.email,
                type: tx.type === "deposit" ? "deposit" : "withdraw",
                method: tx.method,
                amount: tx.amount,
                return_url: base_url
            };
        } else {
            request = {
                user: thunkApi.getState().user.email,
                type: tx.type === "deposit" ? "deposit" : "withdraw",
                method: tx.method,
                amount: tx.amount,
                card: tx.method === 'creditcard' ? tx.card : undefined
            };
        }

        const response = await fetch(url.toString(), {
            method: "PUT",
            headers: new Headers({
                'content-type': 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body: JSON.stringify(request)
        });
        if (response.status === 401 || response.status === 403)
            return thunkApi.rejectWithValue("Unauthorized");

        if (response.status != 200 && response.status != 201 && response.status != 202 && response.status != 301)
            return thunkApi.rejectWithValue("Service unavailable, type again later");

        return (await response.json()) as TransactionResponse
    });

const initialState = {
    loading: false,
    submitDisabled: true
} as TransactionState

function EvaluateSubmitDisabled(loading: boolean, amount: number | undefined, method: TransactionMethod | undefined) {
    return loading || amount === undefined || amount <= 0 || method === undefined;
}

function Validate(type: TransactionType | undefined, balance: number | undefined, amount: number | undefined,
                  status: TransactionStatus | undefined, message: string | undefined): string | undefined {
    if ((status === "declined" || status === "error") && message != undefined)
        return message;

    if (type === "withdraw" && amount != undefined && balance != undefined && amount > balance)
        return "The withdrawal amount exceeds your balance. Please enter a smaller amount.";

    return undefined;
}

function InterpretStatus(message: TransactionCompleteMessage): TransactionStatus | undefined {
    switch (message.status) {
        case 'OK':
            return (message.tx_action == 'PAYMENT' || message.tx_action == 'SETTLEMENT' || message.tx_action == 'CREDIT') ? 'approved' : 'pending';

        case 'EXC':
            return 'declined';

        case 'PEND':
            return 'pending';

        case 'PAYG_ERROR':
            return 'error';

        default:
            return undefined;
    }
}

const slice = createSlice({
    name: 'transaction',
    initialState: initialState,
    reducers: {
        initialize: (state, action: PayloadAction<TransactionType>) => {
            const type = action.payload;
            const amount = undefined;
            const status = undefined;
            const method = type == "withdraw" ? "wallet88" : undefined;

            state.type = type;
            state.amount = amount;
            state.status = status;
            state.method = method;
            state.submitDisabled = EvaluateSubmitDisabled(state.loading, amount, method);
            state.message = Validate(type, state.balance, amount, status, state.message);
            state.reason = undefined;
            return state;
        },
        updateAmount: (state, action: PayloadAction<number>) => {
            const amount = isNaN(action.payload) ? undefined : action.payload;
            state.amount = amount;
            state.message = Validate(state.type, state.balance, amount, state.status, state.message);
            state.submitDisabled = EvaluateSubmitDisabled(state.loading, amount, state.method);
            return state;
        },
        updateMethod: (state, action: PayloadAction<TransactionMethod | undefined>) => {
            const method = action.payload;
            state.method = method;
            state.submitDisabled = EvaluateSubmitDisabled(state.loading, state.amount, method);
            return state;
        },
        updateCard: (state, action: PayloadAction<CreditCard | undefined>) => {
            state.card = action.payload;
            return state;
        },
        updateStatus: (state, action: PayloadAction<TransactionCompleteMessage>) => {
            const status = InterpretStatus(action.payload);
            state.status = status;
            state.reason = status == "declined" ? "NotAccepted" : undefined;
            state.content = undefined;
            state.contentType = undefined;
            return state;
        }
    },
    extraReducers: builder => {
        builder.addCase(requestTransaction.pending, (state) => {
            state.loading = true;
            state.submitDisabled = true;
            state.status = undefined;
            state.message = undefined;
            state.reason = undefined;
            state.content = undefined;
            return state;
        });
        builder.addCase(requestTransaction.fulfilled, (state, action) => {
            const payload = action.payload;
            state.loading = false;
            state.status = payload.status;
            state.reason = payload.reason;
            state.contentType = payload["content-type"];
            state.content = payload.content;
            return state;
        });
        builder.addCase(requestTransaction.rejected, (state, action) => {
            state.loading = false;
            state.status = "error";
            state.message = action.payload;
            return state;
        });
        builder.addCase(requestAccountDetails.pending, (state) => {
            const balance = undefined;
            state.balance = balance;
            state.message = Validate(state.type, balance, state.amount, state.status, state.message);
        });
        builder.addCase(requestAccountDetails.fulfilled, (state, action) => {
            const balance = action.payload.balanceAmount;
            state.balance = balance;
            state.message = Validate(state.type, balance, state.amount, state.status, state.message);
        });
    }
})

export const {
    initialize,
    updateAmount,
    updateMethod,
    updateCard,
    updateStatus
} = slice.actions;

export default slice.reducer;