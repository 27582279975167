import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from "./index";

interface AccountState {
    balance: string,
    loading: boolean
    message: string | undefined,
}

interface AccountDetailsResponse {
    balance: string,
    balanceAmount: number
}

export const requestAccountDetails = createAsyncThunk<AccountDetailsResponse, string, { state: RootState, rejectValue: string }>(
    'accountDetails',
    async (user, thunkApi) => {
        const token = thunkApi.getState().user.accessToken;
        const base_url = window.location.origin;
        const url = new URL(base_url + "/account");

        const response = await fetch(url.toString(), {
            headers: new Headers({
                'Authorization': `Bearer ${token}`
            })
        });
        if (response.status === 401 || response.status === 403)
            return thunkApi.rejectWithValue("Unauthorized");

        if (response.status === 404)
            return thunkApi.rejectWithValue("Account not found");

        return (await response.json()) as AccountDetailsResponse
    });

const initialState = {
    balance: "$0.00",
    loading: false
} as AccountState

const slice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(requestAccountDetails.pending, (state) => {
            state.loading = true;
            return state;
        });
        builder.addCase(requestAccountDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.balance = action.payload.balance
            return state;

        });
        builder.addCase(requestAccountDetails.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload;
            return state;
        });
    }
})

export const {} = slice.actions;

export default slice.reducer;